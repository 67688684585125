import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import Locations from "./components/Locations";
import Menu from "./components/Menu";
import Contact from "./components/Contact";
import Nomatch from "./components/Nomatch";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Locations} />
          <Route
            exact
            path="/location/:locationId/:templateId/menu"
            component={Menu}
          />
          <Route
            exact
            path="/location/:locationId/:templateId/contact"
            component={Contact}
          />
          <Redirect from="/menu/" to="/" push />
          <Route component={Nomatch} />
        </Switch>
      </div>
    </Router>
  );
}
