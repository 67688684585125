import React from "react";

import Header from "./common/Header";
import Footer from "./common/Footer";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

let renderLocation;

// if (this.state.locationId === "1") {
//   renderLocation = { lat: 38.691464, lng: -121.44484 };
// } else {
//   renderLocation = { lat: 38.5906806, lng: -121.3028127 };
// }

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: 38.691464, lng: -121.44484 }}
    >
      <Marker position={{ lat: 38.691464, lng: -121.44484 }} />
    </GoogleMap>
  ))
);

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationId: this.props.match.params.templateId,
      name: "",
      email: "",
      message: "",
      recaptcha: "",
      captchaVerify: false,
      passedForm: false
    };
  }

  render() {
    return (
      <div className="contactContainer">
        <div className="fluid-container">
          <Header
            locationId={this.props.match.params.locationId}
            templateId={this.props.match.params.templateId}
          />
          <div className="googleContainer">
            <MapWithAMarker
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJh0B_CXUkWPZMhGmHxw4FCoGXjBTLbo8&v=3.exp&libraries=geometry,drawing,places"
              }
              loadingElement={<div style={{ height: "100%" }} />}
              containerElement={<div style={{ height: "50vh" }} />}
              mapElement={<div style={{ height: "100%" }} />}
            />
          </div>
          <div className="container formContainer">
            <div className="row text-center">
              {this.state.locationId === "1" ? (
                <React.Fragment>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Write a Google Review</h2>
                    <a
                      href="https://goo.gl/zoKp4Z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-google fa-4x"></i>
                    </a>
                  </div>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Write a Yelp Review</h2>
                    <a
                      href="https://www.yelp.com/biz/bowinkles-restaurant-rio-linda"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-yelp fa-4x"></i>
                    </a>
                  </div>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Like on Facebook</h2>
                    <a
                      href="https://www.facebook.com/pages/Bowinkles/152251514818823"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f fa-4x"></i>
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Write a Google Review</h2>
                    <a
                      href="https://goo.gl/zoKp4Z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-google fa-4x pb-2"></i>
                    </a>
                  </div>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Write a Yelp Review</h2>
                    <a
                      href="https://www.yelp.com/biz/bowinkle-drive-thru-rancho-cordova"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-yelp fa-4x pb-2"></i>
                    </a>
                  </div>
                  <div className="col-md-3 img-thumbnail m-4">
                    <h2 className="text-center py-2">Like on Facebook</h2>
                    <a
                      href="https://www.facebook.com/Bowinkle-Drive-Thru-Rancho-112045923495549/?rf=258331081514609"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f fa-4x pb-2"></i>
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <Footer templateId={this.props.match.params.templateId} />
        </div>
      </div>
    );
  }
}

export default Contact;
