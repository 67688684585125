import React from "react";

const MenuPic = (props) => {
  const { storeId, storeName } = props;

  // console.log("location id is", storeId);
  // console.log("store id is", storeName);
  return (
    <div className="menupicContainer my-4">
      <h1 className="text-center">Bowinkles Menu &amp; Prices</h1>
      <div className="menuPic">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <img
              src={
                storeId === "1"
                  ? "/images/Bowinkles-Menu-riolinda-Part1.jpg"
                  : "/images/Bowinkles-Menu-rancho-Part1.jpg"
              }
              alt={"Bowinkles Menu"}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-lg-5">
            <img
              src={
                storeId === "1"
                  ? "/images/Bowinkles-Menu-riolinda-Part2.jpg"
                  : "/images/Bowinkles-Menu-rancho-Part2.jpg"
              }
              alt={"Bowinkles Menu"}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="menuPdf mt-3">
          <div className="row justify-content-center">
            <a
              href="/images/BowinklesMenu.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="mt-5 font-weight-bold" id="uniqueButton">
                Bowinkles Menu PDF
              </button>
            </a>
          </div>
        </div>
        <div className="foodMedias mt-5">
          <h1 className="text-center">View Our Menu Through These Apps</h1>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="appMedia text-center">
                <a
                  href={
                    storeId === "1"
                      ? "https://www.doordash.com/store/bowinkle-s-drive-thru-rio-linda-910816/1366546/?preview=1"
                      : "https://www.doordash.com/store/bowinkle-s-drive-thru-rancho-cordova-966428/1445437/?preview=1"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/DoorDashicon.jpg" alt="DoorDash Icon" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="appMedia text-center">
                <a
                  href={
                    storeId === "1"
                      ? "https://www.grubhub.com/restaurant/bowinkles-drive-thru-912-oak-ln-rio-linda/2044065?proof=true"
                      : "https://www.grubhub.com/restaurant/bowinkles-drive-thru-rancho-10423-folsom-blvd-rancho-cordova/2053738"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/GrubhubIcon.jpg" alt="Grubhub Icon" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="appMedia text-center">
                <a
                  href={
                    storeId === "1"
                      ? "https://postmates.com/merchant/bowinkles-burger-rio-linda"
                      : "https://postmates.com/merchant/bowinkle-rancho-cordova"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/postmatesicon.jpg" alt="Postmates Icon" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="appMedia text-center">
                {/* Missing Uber Eats Rio Linda Location... */}
                {storeId === "2" ? (
                  <a
                    href="https://www.ubereats.com/sacramento/food-delivery/bowinkles-drive-thru/MYVKzDRhR9qCaARRoQGDpg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/ubereatsiconnew.jpg"
                      alt="Uber Eats Icon"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPic;
