// eslint-disable-next-line default-case
import React from "react";

import AOS from "aos";

import Header from "./common/Header";
import Footer from "./common/Footer";
import MenuPic from "../components/MenuPic";

import appController from "./Controllers";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foodInfo: [
        {
          foodTitle: "Meal Deals",
          foodImage: "/images/foodHeaders/MealDeals.png",
          alt: "Meal Deals Photo",
        },
        {
          foodTitle: "Burgers",
          foodImage: "/images/foodHeaders/Burgers.png",
          alt: "Burgers Photo",
        },
        {
          foodTitle: "Sandwiches",
          foodImage: "/images/foodHeaders/Sandwiches.png",
          alt: "Sandwiches Photo",
        },
        {
          foodTitle: "Mexican",
          foodImage: "/images/foodHeaders/Mexican.png",
          alt: "Mexican Food Photo",
        },
        {
          foodTitle: "Chicken Nuggets",
          foodImage: "/images/foodHeaders/Nuggets.png",
          alt: "Chicken Nuggets Photo",
        },
        {
          foodTitle: "Wings",
          foodImage: "/images/foodHeaders/Wings.png",
          alt: "Wings Photo",
        },
        {
          foodTitle: "Side Orders",
          foodImage: "/images/foodHeaders/sideOrders.png",
          alt: "Side Orders Photo",
        },
        {
          foodTitle: "Drinks",
          foodImage: "/images/foodHeaders/Beverages.png",
          alt: "Drinks Photo",
        },
        {
          foodTitle: "Desserts",
          foodImage: "/images/foodHeaders/Desserts.png",
          alt: "Desserts Photo",
        },
        {
          foodTitle: "Salads",
          foodImage: "/images/foodHeaders/Salads.png",
          alt: "Salads Photo",
        },
        {
          foodTitle: "Blended",
          foodImage: "/images/foodHeaders/Blended.png",
          alt: "Blended Photo",
        },
      ],
      templateId: this.props.match.params.templateId,
      foodId: null,
      defaultthumbnail: "/images/defaultfoodicon.png",
      defaultalt: "Default Meal Photo",
    };
  }

  renderFood = (id) => {
    this.setState({
      foodId: id,
    });
  };

  foodMenu = () => {
    //let foodID = this.state.foodId;
    //console.log(this.state.foodId);

    if (this.state.templateId === "1" && this.state.foodId === null) {
      return this.state.foodInfo
        .filter((d) => d.foodTitle !== "Wings")
        .map((rows, index) => (
          <div
            className="col-md-4 text-center"
            data-aos="fade-top"
            key={index}
            onClick={() => this.renderFood(index)}
          >
            <div className="chooseFoodContainer">
              <div className="overlay">
                <h2>{rows.foodTitle}</h2>
                <img src={rows.foodImage} alt={rows.foodAlt} />
                <div className="content"></div>
              </div>
            </div>
          </div>
        ));
    } else if (this.state.templateId === "2" && this.state.foodId === null) {
      return this.state.foodInfo.map((rows, index) => (
        <div
          className="col-md-4 text-center"
          data-aos="fade-top"
          key={index}
          onClick={() => this.renderFood(index)}
        >
          <div className="chooseFoodContainer">
            <div className="overlay">
              <h2>{rows.foodTitle}</h2>
              <img src={rows.foodImage} alt={rows.foodAlt} />
              <div className="content"></div>
            </div>
          </div>
        </div>
      ));
    } else {
      return null;
    }
  };

  renderNavigation = () => {
    let foodHeader;
    let foodID = this.state.foodId;
    // Rio Linda Location
    if (this.state.templateId === "1") {
      switch (foodID) {
        case 0:
          return (foodHeader = "Meal Deals");
          break;
        case 1:
          return (foodHeader = "Burgers");
          break;
        case 2:
          return (foodHeader = "Sandwiches");
          break;
        case 3:
          return (foodHeader = "Mexican");
          break;
        case 4:
          return (foodHeader = "Chicken Nuggets");
          break;
        case 5:
          return (foodHeader = "Side Orders");
          break;
        case 6:
          return (foodHeader = "Drinks");
          break;
        case 7:
          return (foodHeader = "Desserts");
          break;
        case 8:
          return (foodHeader = "Salads");
          break;
        case 9:
          return (foodHeader = "Blended");
          break;
      }
    } else {
      // Rancho Location
      switch (foodID) {
        case 0:
          return (foodHeader = "Meal Deals");
          break;
        case 1:
          return (foodHeader = "Burgers");
          break;
        case 2:
          return (foodHeader = "Sandwiches");
          break;
        case 3:
          return (foodHeader = "Mexican");
          break;
        case 4:
          return (foodHeader = "Chicken Nuggets");
          break;
        case 5:
          return (foodHeader = "Wings");
          break;
        case 6:
          return (foodHeader = "Side Orders");
          break;
        case 7:
          return (foodHeader = "Drinks");
          break;
        case 8:
          return (foodHeader = "Desserts");
          break;
        case 9:
          return (foodHeader = "Salads");
          break;
        case 10:
          return (foodHeader = "Blended");
          break;
      }
    }
  };

  // componentDidMount = () => {
  //   console.log(this.state);
  // };

  resetCounter = (reset) => {
    //console.log(id);
    this.setState({
      foodId: reset,
    });
  };

  setContent = () => {
    let foodID = this.state.foodId;
    // console.log(foodID);
    // Rio Linda Location

    if (this.state.templateId === "1") {
      switch (foodID) {
        // Meal Deals
        case 0:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Meal Deals</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/cheeseburger.jpg"
                        alt="Cheese Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>1/4lb Cheese Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/corndogmeal.jpg"
                        alt="Corn Dog Meal"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>2 Corn Dogs</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbohotdog.jpg"
                        alt="Jumbo Hot Dog Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Hot Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggetsmeal.jpg"
                        alt="Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>6 pc. Chicken Nuggets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchburgermeal.jpg"
                        alt="French Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/westernbaconmeal.jpg"
                        alt="Western Bacon Cheese Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Western Bacon Cheese Burger</p>
                        <p id="foodDescription">
                          1/4lb. BBQ, onion rings, bacon, cheese and sesame seed
                          bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickendeluxmeal.jpg"
                        alt="Chicken Delux"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Deluxe</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burritomeal.jpg"
                        alt="Burrito Meal"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>2 Burritos</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/tacomeal.jpg"
                        alt="Taco Meal Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>3 Tacos</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishsandwichmeal.jpg"
                        alt="Fish Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Sandwhich</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 1:
          return (
            // Burgers
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Burgers</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchburger.jpg"
                        alt="French Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Burger</p>
                        <p id="foodDescription">1/2lb. on French roll</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/halfpounder.jpg"
                        alt="Half Pounder Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Half Pounder</p>
                        <p id="foodDescription">1/2lb. on sesame seed bun</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/14pounder.jpg"
                        alt="1/4 Pounder Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>1/4 Pounder</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/double14pounder.jpg"
                        alt="Double 1/4 Pounder Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Double 1/4 Pounder</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/childburger.jpg"
                        alt="Child Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Child Burger</p>
                        <p id="foodDescription">
                          Add Cheese $0.50, Add Bacon $1.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hamaneer.jpg"
                        alt="Ham A Neer Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ham-A-Neer</p>
                        <p id="foodDescription">
                          1/4lb. ham, mayo, pickle, tomato, lettuce, cheese and
                          sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mushroomburger.jpg"
                        alt="Mushroom Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mushroom Burger</p>
                        <p id="foodDescription">
                          1.4lb. grilled onion, mustard, cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/oldfashionmelt.jpg"
                        alt="Old Fashioned Melt Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Old Fashion Melt</p>
                        <p id="foodDescription">
                          1/4lb. grill onion, mustard, cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/southwesternburger.jpg"
                        alt="South Western Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>South Western Burger</p>
                        <p id="foodDescription">
                          1/4lb. grill onion, salsa, jalapeno, mayo, pickle,
                          tomato, lettuce, cheese and sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/grilledchickensandwich.jpg"
                        alt="Grilled Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Grilled Chicken Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/westernbaconcheese.jpg"
                        alt="Western Bacon Cheese Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Western Bacon Cheese</p>
                        <p id="foodDescription">
                          1/4lb. BBQ, onion ring, bacon, cheese and sesame seed
                          bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/doublewesterncheeseburger.jpg"
                        alt="Double Western Bacon Cheese Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Double Western Bacon Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 2:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Sandwiches</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/sourdoughranchchickensandwich.jpg"
                        alt="Sourdough Ranch Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Sourdough Ranch Chicken</p>
                        <p id="foodDescription">
                          Chicken patty, ranch, bacon, pickle, tomato, lettuce,
                          cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickendelux.jpg"
                        alt="Chicken Deluxe Photo"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Deluxe</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/bbqchicken.jpg"
                        alt="BBQ Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>BBQ Chicken</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Teriyaki Chicken</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/santafechicken.jpg"
                        alt="Santa Fe Chicken Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Santa Fe Chicken</p>
                        <p id="foodDescription">
                          Chicken patty, grill onion, salsa, jalapeno, mayo,
                          pickle, tomato, lettuce, cheese and sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hothamandcheese.jpg"
                        alt="Hot Ham and Cheese Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Hot Ham &amp; Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/grilledcheese.jpg"
                        alt="Grilled Cheese"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Grilled Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/blt.jpg"
                        alt="BLT Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>B.L.T</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hamsandwich.jpg"
                        alt="Ham Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ham Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/clubsandwich.jpg"
                        alt="Club Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Club Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbohotdogsupreme.jpg"
                        alt="Jumbo Hot Dog Supreme Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Hot Dog Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hotlink.jpg"
                        alt="Hot Link Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Hot Link</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/pastrami.jpg"
                        alt="Pastrami Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Pastrami</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishsandwich.jpg"
                        alt="Fish Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickenstripmeal.jpg"
                        alt="Chicken Strip"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Strip</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishfillet.jpg"
                        alt="Fish Fillet Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Fillet</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbocorndog.jpg"
                        alt="Jumbo Corn Dog Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Corn Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 3:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Mexican</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/nacho.jpg"
                        alt="Nachos"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Nachos</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Nacho Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burritowrap.jpg"
                        alt="Burrito Wrap"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Burrito Wraps</p>
                        <p id="foodDescription">(Beef or chicken)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burrito.jpg"
                        alt="Beef and Bean Burrito"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Beef &amp; Bean Burrito</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/taco.jpg"
                        alt="Taco Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taco</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/tacosupreme.jpg"
                        alt="Taco Supreme Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taco Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/toquitos.jpg"
                        alt="Taquitos"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taquitos</p>
                        <p id="foodDescription">
                          3pc. w/ guacamole &amp; sour cream
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/rushlimbaughmeal.jpg"
                        alt="Rush Limbaugh Meal Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Rush Limbaugh Meal</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Gaggler Meal</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 4:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Chicken Nuggets</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="6 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>6 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="9 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>9 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="20 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>20 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 5:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Side Orders</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchfries.jpg"
                        alt="French Fries"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Fries</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchfries.jpg"
                        alt="French Fries Super-Size"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Fries Super-Size</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/onionrings.jpg"
                        alt="Onion Rings"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Onion Rings</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/zucchini.jpg"
                        alt="Zucchini"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Zucchini</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mushroom.jpg"
                        alt="Mushroom"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mushroom</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mozzarellasticks.jpg"
                        alt="Mozzarella Sticks"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mozzarella</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jalapenopoppers.jpg"
                        alt="Jalapeno Poppers"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jalapeno Poppers</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chilicheesenacho.jpg"
                        alt="Chili Cheese Nacho"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Nacho</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chilicheesefries.jpg"
                        alt="Chili Cheese Fries"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Fries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Cheese Fries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 6:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Drinks</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/cocacola.jpg"
                        alt="Drink Choice Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>
                          Coke, Cherry Coke, Diet Coke, Root Beer, Mr. Pibb,
                          Sprite, Raspberry Iced Tea, Pink Lemonade, Iced Tea
                        </p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-4">Small</div>
                            <div className="col-md-4">Medium</div>
                            <div className="col-md-4">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icedmocha.jpg"
                        alt="Iced Mocha Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Iced Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icedmocha.jpg"
                        alt="Iced White Mocha Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Iced White Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/milk.jpg"
                        alt="Milk Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Milk</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/redbull.jpg"
                        alt="Redbull Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Red Bull</p>
                        <p id="foodDescription">(Blended or Ice)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 7:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Desserts</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icecreamcone.jpg"
                        alt="Ice Cream Cone"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ice Cream Cone</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Floats</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chocolatesundaes.jpg"
                        alt="Sundaes Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Sundaes</p>
                        <p id="foodDescription">Choose your own flavor</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/banannasplit.jpg"
                        alt="Bananna Split Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Banana Split</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/sake.jpg"
                        alt="Bananna Split Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Milk Shakes</p>
                        <div id="foodDescription">
                          <p id="foodDescription">
                            Chocolate, Vanilla, Strawberry, Banana, Pineapple,
                            Blackberry, Butterscotch, Oreo, Butterfinger, Pink
                            Panther (Lemonade and Ice Cream mixed), Mr. Pibb,
                            Root Beer, Cherry, Peanut Butter, Mocha, Caramel...
                            MAKE IT YOUR WAY – Add 25¢ for each flavor
                          </p>
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 8:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Salads</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chefsalad.jpg"
                        alt="Chef Salad Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chef Salad</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickensalad.jpg"
                        alt="Chicken Salad Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Salad</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/housesalad.jpg"
                        alt="House Salad Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>House Salad</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickencaesarsalad.jpg"
                        alt="Chicken Caesar Salad Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Caesar Salad</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case 9:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Blended</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/strawberrydrink.jpg"
                        alt="Strawberry Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Strawberry Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/peachdrink.jpg"
                        alt="Peach Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Peach Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/wildberrydrink.jpg"
                        alt="Wild Berry Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Wild Berry Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/pinacoladadrink.jpg"
                        alt="Pina Colada Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Pina Colada Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chocolatemocha.jpg"
                        alt="Chocolate Mocha"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chocolate Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/whitechocolatemocha.jpg"
                        alt="White Chocolate Mocha"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>White Chocolate Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
      }
    } else {
      // Rancho Menu
      switch (foodID) {
        // Meal Deals
        case 0:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Meal Deals</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/cheeseburger.jpg"
                        alt="Cheese Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>1/4lb Cheese Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/corndogmeal.jpg"
                        alt="Corn Dog Meal"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>2 Corn Dogs</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbohotdog.jpg"
                        alt="Jumbo Hot Dog Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Hot Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggetsmeal.jpg"
                        alt="Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>6 pc. Chicken Nuggets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchburgermeal.jpg"
                        alt="French Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/westernbaconmeal.jpg"
                        alt="Western Bacon Cheese Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Western Bacon Cheese Burger</p>
                        <p id="foodDescription">
                          1/4lb. BBQ, onion rings, bacon, cheese and sesame seed
                          bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickendeluxmeal.jpg"
                        alt="Chicken Delux"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Deluxe</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burritomeal.jpg"
                        alt="Burrito Meal"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>2 Burritos</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/tacomeal.jpg"
                        alt="Taco Meal Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>3 Tacos</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishsandwichmeal.jpg"
                        alt="Fish Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Sandwhich</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 1:
          return (
            // Burgers
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Burgers</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchburger.jpg"
                        alt="French Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Burger</p>
                        <p id="foodDescription">1/2lb. on French roll</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/halfpounder.jpg"
                        alt="Half Pounder Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Half Pounder</p>
                        <p id="foodDescription">1/2lb. on sesame seed bun</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/14pounder.jpg"
                        alt="1/4 Pounder Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>1/4 Pounder</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/double14pounder.jpg"
                        alt="Double 1/4 Pounder Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Double 1/4 Pounder</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/childburger.jpg"
                        alt="Child Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Child Burger</p>
                        <p id="foodDescription">
                          Add Cheese $0.50, Add Bacon $1.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hamaneer.jpg"
                        alt="Ham A Neer Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ham-A-Neer</p>
                        <p id="foodDescription">
                          1/4lb. ham, mayo, pickle, tomato, lettuce, cheese and
                          sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mushroomburger.jpg"
                        alt="Mushroom Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mushroom Burger</p>
                        <p id="foodDescription">
                          1.4lb. grilled onion, mustard, cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/oldfashionmelt.jpg"
                        alt="Old Fashioned Melt Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Old Fashion Melt</p>
                        <p id="foodDescription">
                          1/4lb. grill onion, mustard, cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/southwesternburger.jpg"
                        alt="South Western Burger"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>South Western Burger</p>
                        <p id="foodDescription">
                          1/4lb. grill onion, salsa, jalapeno, mayo, pickle,
                          tomato, lettuce, cheese and sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/grilledchickensandwich.jpg"
                        alt="Grilled Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Grilled Chicken Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/westernbaconcheese.jpg"
                        alt="Western Bacon Cheese Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Western Bacon Cheese</p>
                        <p id="foodDescription">
                          1/4lb. BBQ, onion ring, bacon, cheese and sesame seed
                          bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/doublewesterncheeseburger.jpg"
                        alt="Double Western Bacon Cheese Burger Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Double Western Bacon Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Burger</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 2:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Sandwiches</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/sourdoughranchchickensandwich.jpg"
                        alt="Sourdough Ranch Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Sourdough Ranch Chicken</p>
                        <p id="foodDescription">
                          Chicken patty, ranch, bacon, pickle, tomato, lettuce,
                          cheese on sourdough bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickendelux.jpg"
                        alt="Chicken Deluxe Photo"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Deluxe</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/bbqchicken.jpg"
                        alt="BBQ Chicken Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>BBQ Chicken</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Teriyaki Chicken</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/santafechicken.jpg"
                        alt="Santa Fe Chicken Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Santa Fe Chicken</p>
                        <p id="foodDescription">
                          Chicken patty, grill onion, salsa, jalapeno, mayo,
                          pickle, tomato, lettuce, cheese and sesame seed bun
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hothamandcheese.jpg"
                        alt="Hot Ham and Cheese Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Hot Ham &amp; Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/grilledcheese.jpg"
                        alt="Grilled Cheese"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Grilled Cheese</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/blt.jpg"
                        alt="BLT"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>B.L.T</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hamsandwich.jpg"
                        alt="Ham Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ham Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/clubsandwich.jpg"
                        alt="Club Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Club Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbohotdogsupreme.jpg"
                        alt="Jumbo Hot Dog Supreme Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Hot Dog Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hotlink.jpg"
                        alt="Hot Link Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Hot Link</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/pastrami.jpg"
                        alt="Pastrami Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Pastrami</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishsandwich.jpg"
                        alt="Fish Sandwich Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Sandwich</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickenstripmeal.jpg"
                        alt="Chicken Strip"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Strip</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/fishfillet.jpg"
                        alt="Fish Fillet Sandwich"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Fish Fillet</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jumbocorndog.jpg"
                        alt="Jumbo Corn Dog Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Corn Dog</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 3:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Mexican</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/nacho.jpg"
                        alt="Nachos"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Nachos</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Nacho Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burritowrap.jpg"
                        alt="Burrito Wrap"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jumbo Burrito Wraps</p>
                        <p id="foodDescription">(Beef or chicken)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/burrito.jpg"
                        alt="Beef and Bean Burrito"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Beef &amp; Bean Burrito</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/taco.jpg"
                        alt="Taco Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taco</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/tacosupreme.jpg"
                        alt="Taco Supreme Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taco Supreme</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/toquitos.jpg"
                        alt="Taquitos"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Taquitos</p>
                        <p id="foodDescription">
                          3pc. w/ guacamole &amp; sour cream
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/rushlimbaughmeal.jpg"
                        alt="Rush Limbaugh Meal Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Rush Limbaugh Meal</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Gaggler Meal</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 4:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Chicken Nuggets</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="6 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>6 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="9 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>9 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickennuggets.jpg"
                        alt="20 Piece Chicken Nuggets Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>20 Piece</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 5:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Wings</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jalapenowings.jpg"
                        alt="Jalapeno Pepper Wings"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jalapeno Pepper Wings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/hotwings.jpg"
                        alt="Hot Wings"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Hot Wings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/bbqwings.jpg"
                        alt="BBQ Wings"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>BBQ Wings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 6:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Side Orders</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchfries.jpg"
                        alt="French Fries"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Fries</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/frenchfries.jpg"
                        alt="French Fries Super-Size"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>French Fries Super-Size</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/onionrings.jpg"
                        alt="Onion Rings"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Onion Rings</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/zucchini.jpg"
                        alt="Zucchini"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Zucchini</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mushroom.jpg"
                        alt="Mushroom"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mushroom</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/mozzarellasticks.jpg"
                        alt="Mozzarella Sticks"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Mozzarella</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/jalapenopoppers.jpg"
                        alt="Jalapeno Poppers"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Jalapeno Poppers</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chilicheesenacho.jpg"
                        alt="Chili Cheese Nacho"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Nacho</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chilicheesefries.jpg"
                        alt="Chili Cheese Fries"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chili Cheese Fries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Cheese Fries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 7:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Drinks</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/cocacola.jpg"
                        alt="Choose Drink Pictures"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>
                          Coke, Cherry Coke, Diet Coke, Root Beer, Mr. Pibb,
                          Sprite, Raspberry Iced Tea, Pink Lemonade, Iced Tea
                        </p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-4">Small</div>
                            <div className="col-md-4">Medium</div>
                            <div className="col-md-4">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icedmocha.jpg"
                        alt="Iced Mocha Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Iced Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icedmocha.jpg"
                        alt="Iced White Mocha Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Iced White Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/milk.jpg"
                        alt="Milk Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Milk</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/redbull.jpg"
                        alt="Redbull Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Red Bull</p>
                        <p id="foodDescription">(Blended or Ice)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 8:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Desserts</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/icecreamcone.jpg"
                        alt="Ice Cream Cone"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Ice Cream Cone</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src={this.state.defaultthumbnail}
                        alt={this.state.defaultalt}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Floats</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chocolatesundaes.jpg"
                        alt="Sundaes Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Sundaes</p>
                        <p id="foodDescription">Choose your own flavor</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/banannasplit.jpg"
                        alt="Bananna Split Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Banana Split</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/sake.jpg"
                        alt="Bananna Split Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Milk Shakes</p>
                        <div id="foodDescription">
                          <p id="foodDescription">
                            Chocolate, Vanilla, Strawberry, Banana, Pineapple,
                            Blackberry, Butterscotch, Oreo, Butterfinger, Pink
                            Panther (Lemonade and Ice Cream mixed), Mr. Pibb,
                            Root Beer, Cherry, Peanut Butter, Mocha, Caramel...
                            MAKE IT YOUR WAY – Add 25¢ for each flavor
                          </p>
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 9:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Salads</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chefsalad.jpg"
                        alt="Chef Salad Picture"
                      />
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickensalad.jpg"
                        alt="Chicken Salad Picture"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/housesalad.jpg"
                        alt="House Salad Picture"
                      />
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chickencaesarsalad.jpg"
                        alt="Chicken Caesar Salad Picture"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chicken Caesar Salad</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case 10:
          return (
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <h2>Blended</h2>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/strawberrydrink.jpg"
                        alt="Strawberry Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Strawberry Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/peachdrink.jpg"
                        alt="Peach Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Peach Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/wildberrydrink.jpg"
                        alt="Wild Berry Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Wild Berry Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/pinacoladadrink.jpg"
                        alt="Pina Colada Smoothie"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Pina Colada Smoothie</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/chocolatemocha.jpg"
                        alt="Chocolate Mocha"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>Chocolate Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="meal">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-thumbnail"
                        src="/images/foodPictures/whitechocolatemocha.jpg"
                        alt="White Chocolate Mocha"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="menuItem">
                        <p>White Chocolate Mocha</p>
                        <div id="foodDescription">
                          <div className="row">
                            <div className="col-md-6">Small</div>
                            <div className="col-md-6">Large</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
      }
    }
  };

  componentDidMount = () => {
    AOS.init();
  };

  render() {
    return (
      <div className="container-fluid">
        <Header
          locationId={this.props.match.params.locationId}
          templateId={this.props.match.params.templateId}
        />
        <div className="reviewCards">
          <h2 className="py-4 text-center">Customer Reviews</h2>
          <div className="row justify-content-center">
            <div className="col-md-3" data-aos="fade-left">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">"Good Deal For The Price"</h5>
                  <p className="card-text">
                    “Had the French burger combo. Good burger, good fries. Great
                    deal for the price. The car in front of me had a large
                    order, making for a bit of wait but the man at the window
                    was very apologetic about the delay.”
                  </p>
                  <p className="card-text text-right">Rodney R.</p>
                  <div className="pt-2 text-center">
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-left">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">"Great food and great people"</h5>
                  <p className="card-text">
                    “Great food and great people. Food is prepared very quickly.
                    It’s like magic. We have been coming here for many years and
                    always order the French roll burger. The best ever!!!!”
                  </p>
                  <p className="card-text text-right">Marinna M.</p>
                  <div className="pt-2 text-center">
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-left">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    "You have to try their banana split"
                  </h5>
                  <p className="card-text">
                    “Such delicious food. If you go there, you have to try their
                    banana split. But beware, it isn’t nut free. Support them.
                    You’ll thank me later.”
                  </p>
                  <p className="card-text text-right">Tia M.</p>
                  <div className="pt-2 text-center">
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-left">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">"Wide variety on the menu"</h5>
                  <p className="card-text">
                    “One of my favorite places to eat also they have a pound and
                    a-half burger. Their milkshakes are one of the best in
                    Sacramento. I would suggest that to anyone who have ever
                    went there to go there. Have a wide variety on the menu from
                    corn dogs sandwiches, burrito, hamburgers and more. This is
                    one of the best Rio Linda places to ever eat.”
                  </p>
                  <p className="card-text text-right">Rob C.</p>
                  <div className="pt-2 text-center">
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                    <i className="fas fa-star checked"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Menu Pic Container Component */}
        <MenuPic
          storeId={this.props.match.params.templateId}
          storeName={this.props.match.params.locationId}
        />

        <div className="menuContainer">
          <div className="container">
            <h1 className="text-center">
              {"Menu " +
                appController.titleChange(this.props.match.params.locationId)}
            </h1>
            <h2 onClick={() => this.resetCounter(null)}>
              {this.state.foodId === null ? (
                <p className="breadcrumb" style={{ cursor: "pointer" }}>
                  Menu
                </p>
              ) : (
                <p className="breadcrumb" style={{ cursor: "pointer" }}>
                  {" "}
                  Menu / {this.renderNavigation()}
                </p>
              )}
            </h2>
            <div className="row justify-content-center">{this.foodMenu()}</div>
            <div className="foodContainer">{this.setContent()}</div>
          </div>
        </div>
        <Footer templateId={this.props.match.params.templateId} />
      </div>
    );
  }
}

export default Menu;
