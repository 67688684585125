// 404 page
import React from "react";

import Footer from "./common/Footer";

import { Link } from "react-router-dom";
import AOS from "aos"; // Animate on scroll

class Nomatch extends React.Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="noMatchcontainer">
        <div className="fluidContainer">
          <div className="fluidContainer" id="locationHeader">
            <h1 className="text-center">Bowinkles Drive Thru</h1>
          </div>

          <div className="bgimage">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-6 col-md-auto">
                  <div className="bgContent">
                    <div className="overlay">
                      <div data-aos="fade-down" data-aos-once="true">
                        {/* <h1>404 - PAGE NOT FOUND</h1> */}
                        <span className="bigText">4</span>
                        <i className="fas fa-hamburger fa-7x"></i>
                        <span className="bigText">4</span>
                        <p className="text-center sorryno">
                          &#10035; Sorry no food here. &#10035;
                        </p>
                        <p className="text-center message">
                          Sorry but the page you are looking for does not exist,
                          have been removed, name changed or is temporarily
                          unavailable.
                        </p>
                        <Link to="/">
                          <button className="text-center" id="uniqueButton">
                            BACK TO HOMEPAGE
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Nomatch;
