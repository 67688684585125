var appController = {
  titleChange(data) {
    let splitString = data;
    splitString = splitString.replace("-", " ");

    return splitString;
  }
};

export default appController;
